/**
 * Function to set the theme.
 * @since v1.0.0
 * @returns null
 */
const themeSetup = () => {
    const appTheme = localStorage.getItem('theme') || 'light';

      if (
        appTheme === 'dark' &&
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.contains('app-theme')
      ) {
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.remove('bg-secondary-light');
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.add('bg-primary-dark');
      } else {
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.remove('bg-primary-dark');
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.add('bg-secondary-light');
      }
}

const clearCache = async () => {
  // clear local storage all data.
  await localStorage.clear();

  // clear all cache data.
  await caches.keys().then((keyList: any) =>
    Promise.all(
      keyList.map((key: any) => {
          return caches.delete(key);
      })
    )
  )
}

const generatePaginationList = (pagination: any) => {
  let list: any[] = [];
  let current = pagination.current_page
  for (let i = 1; i <= pagination.last_page; i++) {
    if (i === current) {
      list.push(i);
    } else if (i <= 3 || i > pagination.last_page - 2 || i >= pagination.current_page - 1 && i <= pagination.current_page + 1) {
      list.push(i);
    } else if (i === 4 || i === pagination.last_page - 3) {
      list.push('...');
    }
  }
  list = list.filter((item, pos) => {
    return list.indexOf(item) === pos;
  });
  return list;
}

const openViewHelper = (id: string) => {
  let elem = document.getElementById(id);
  // Check if it has class hidden,
  // if it has remove it, else add it.
  // @ts-ignore: Object is possibly 'null'.
  if (elem.classList.contains('hidden')) {
    // @ts-ignore: Object is possibly 'null'.
    elem.classList.remove('hidden');
  } else {
    // @ts-ignore: Object is possibly 'null'.
    elem.classList.add('hidden');
  }
}

const tabViewHelper = (toShow:any , toHide: any) => {
  let elem1 = document.getElementById(toShow);
  let elem2 = document.getElementById(toHide);
  // @ts-ignore: Object is possibly 'null'.
  elem1.classList.remove('hidden');
  // @ts-ignore: Object is possibly 'null'.
  elem2.classList.add('hidden');
}

const toggelLabelHelper = (id: string, newLabel: string, oldLabel: string) => {
  let elem = document.getElementById(id);
  // @ts-ignore: Object is possibly 'null'.
  if (elem.innerHTML === newLabel) {
    // @ts-ignore: Object is possibly 'null'.
    elem.innerHTML = oldLabel;
  } else {
    // @ts-ignore: Object is possibly 'null'.
    elem.innerHTML = newLabel;
  }
}

const searchFiltersHelper = (filters: any) => {
  let newFilters :any[] = [];
  for (let i = 0; i < filters.length; i++) {
    newFilters.push({
      "id": filters[i],
      "text": filters[i].charAt(0).toUpperCase() + filters[i].slice(1)
    });
  }

  return newFilters;
}

export {
  themeSetup,
  generatePaginationList,
  openViewHelper,
  tabViewHelper,
  toggelLabelHelper,
  searchFiltersHelper,
  clearCache
}