import {RouteRecordRaw} from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Auth/LoginView.vue'),
    meta: {
      title: "Home",
      requiresAuth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Auth/RegisterView.vue'),
    meta: {
      title: "Register",
      requiresAuth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'Reset Password',
    component: () => import('../views/Auth/ResetView.vue'),
    meta: {
      title: "Reset Password",
      requiresAuth: false
    }
  },
  {
    path: '/usermgmt',
    name: 'Usermgmt',
    component: () => import('../views/Auth/UserManage.vue'),
    meta: {
      title: "Usermgmt",
      requiresAuth: false
    }
  },
  {
    path: '/admin/dashboard',
    name: 'AdminIndex',
    component: () => import('../views/Admin/DashboardIndex.vue'),
    meta: {
      title: "Admin Dashboard",
      requiresAuth: true,
      requireAdmin: true,
    }
  },
  {
    path: '/admin/manage-user',
    name: 'AdminMangeUsers',
    component: () => import('../views/Admin/Forms/manageUser.vue'),
    meta: {
      title: "Manage User",
      requiresAuth: true,
      requireAdmin: true,
    }
  },
  {
    path: '/admin/manage-rule/:id?',
    name: 'AdminMangeRule',
    component: () => import('../views/Admin/Forms/manageRule.vue'),
    meta: {
      title: "Manage Rule",
      requiresAuth: true,
      requireAdmin: true,
    }
  },
  {
    path: '/admin/view-user',
    name: 'AdminViewUser',
    component: () => import('../views/Admin/View/UserView.vue'),
    meta: {
      title: "View User",
      requiresAuth: true,
      requireAdmin: true,
    }
  },
  {
    path: '/admin/view-rule',
    name: 'AdminViewRule',
    component: () => import('../views/Admin/View/RuleView.vue'),
    meta: {
      title: "View Rule",
      requiresAuth: true,
      requireAdmin: true,
    }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: () => import('../views/Admin/List/UsersView.vue'),
    meta: {
      title: "Users",
      requiresAuth: true,
      requireAdmin: true,
    }
  },
  {
    path: '/admin/rules',
    name: 'AdminRules',
    component: () => import('../views/Admin/List/RulesView.vue'),
    meta: {
      title: "Rules",
      requiresAuth: true,
      requireAdmin: true,
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat/IndexView.vue'),
    meta: {
      title: "Chat",
      requiresAuth: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/User/ProfileView.vue'),
    meta: {
      title: "Profile",
      requiresAuth: true,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: "404",
    component: () => import('../views/site/404View.vue'),
    meta: {
      title: "404 Not Found"
    }
  },
];

export default routes;
