import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/services/firebase'
import routes from './routes'

// Nprogress
import Nprogress from 'nprogress'
// Nprogress CSS
import 'nprogress/nprogress.css';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to: any, from: any, savedPosition: any) {
    if (to.hash) {
      const elem = document.getElementById(to.hash.replace("#", ""))
      if (elem)
        window.scrollTo(0, elem.offsetTop);
    } else {
      // @ts-ignore
      document.getElementById('app').scrollIntoView();
    }
  }
})

// @ts-ignore
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const user:any = auth.currentUser
  Nprogress.start()

  // if user is not logged in redirect to auth page.
  if (requiresAuth && !auth.currentUser) {
    next('/')
  }
  // if require auth is false and user is logged in redirect to dashboard.
  else if (!requiresAuth && user) {
    next('/chat')
  }
  // if user is logged in and require admin is true and user is not admin redirect to dashboard.
  // else if (to.meta.requireAdmin && user && !user.role === 'admin') {
  //   next('/admin/dashboard')
  // }
  next()
})

// @ts-ignore
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  Nprogress.done()
})

export default router
