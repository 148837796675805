import axios from 'axios';

const setApiAuthenticationHeader = (accessToken = null) => {
    if (accessToken) {
        axiosInstance.defaults.headers.common.authorization = `Bearer ${accessToken}`;
    } else {
        delete axiosInstance.defaults.headers.common.authorization;
    }
};

const API_SERVER = "https://us-central1-democratic-ai-4203d.cloudfunctions.net/api"

// const API_SERVER = "http://localhost:5000/democratic-ai-4203d/us-central1/api"

// basic configurations
axios.defaults.baseURL = API_SERVER;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

// create instance 
const axiosInstance = axios.create();
// axiosInstance.defaults.headers.common.authorization = `Bearer ${localStorage.getItem('accessToken')}`;

// Axios wrapper to handle error
const axiosWrapper = apiCall => apiCall.then(res => res.data).catch(err => Promise.reject(err));

// axios interceptors to handle token expiration
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {}
);

// API calls.
let api = {
    signup: (data) => axiosWrapper(axiosInstance.post('/user/signup', data)),
    getMe: () => axiosWrapper(axiosInstance.get(`/user/me`)),
    chat: (data) => axiosWrapper(axiosInstance.post('/chat', data)),
    admin: {
        users: (page, itemsPerPage) => axiosWrapper(axiosInstance.get(`/users?page=${page}&itemsPerPage=${itemsPerPage}`)),
    }
}

export { api, axiosInstance, setApiAuthenticationHeader };

export default api;
